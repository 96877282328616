'use client';
import { useState, useEffect, useContext } from 'react';
import { Heart } from 'lucide-react';
import { FaHeart } from 'react-icons/fa';
import { WishlistContext } from '@/context/WishlistContext';

const AddWishlist = ({ product }) => {
    const { wishlist, toggleWishlist } = useContext(WishlistContext);
    const [wishlistProducts, setWishlistProducts] = useState([])
    
    useEffect(() => {
        setWishlistProducts(wishlist)
    }, [wishlist])

    const isInWishlist = wishlistProducts.some(item => item?._id === product?._id)

    return (
        <>
            {
                isInWishlist ? <FaHeart onClick={() => toggleWishlist(product)} className='text-custom-red cursor-pointer' /> : <Heart
                    size={16}
                    onClick={() => toggleWishlist(product)}
                    className='text-custom-blue cursor-pointer'
                />
            }
        </>
    );
};

export default AddWishlist;
