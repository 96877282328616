import { Eye } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Card, CardContent } from '@/components/ui/card'
import ProductImage from '@/components/layout/ProductImage'
import { Badge } from '@/components/ui/badge'
import { DatabaseZap } from 'lucide-react'
import AddToCart from './AddToCart'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import Link from 'next/link'
import { formatCurrency } from '@/utils/formatCurrency'

const ProductView = ({ product }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Eye size={16} className="rounded text-custom-blue cursor-pointer" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px] overflow-hidden">
        <DialogHeader>
          <DialogTitle className="capitalize">{product?.title}</DialogTitle>
        </DialogHeader>
        <Separator></Separator>
        <Card className="border-none shadow-none">
          <CardContent className="flex gap-4 p-0">
            <div className='w-1/2 h-full'>
            <ProductImage product={product} />
            </div>
            <div className="max-w-sm">
              <h1 className="text-xl font-medium">{product?.title}</h1>
              <p className="text-sm my-2 font-medium"><span className="text-custom-text-blue  mr-2 font-medium">By:</span>{product?.author?.name}</p>
              <p className="text-sm font-medium capitalize"><span className="text-custom-text-blue  mr-2 font-medium">Category:</span>{product?.category}</p>
              <div className="flex items-center my-6">
                {product?.discount === 0 ? (
                  <p className="text-base font-medium">
                    {formatCurrency(product?.price)}
                  </p>
                ) : (
                  <div className="flex items-center gap-4">
                    <div className='flex flex-col items-start'>
                      <p className="text-base font-medium">
                        {formatCurrency(Math.ceil(product?.discountedPrice))}
                      </p>
                      <p className="text-sm font-medium text-custom-text-gray line-through">
                        {formatCurrency(product?.price)}
                      </p>
                    </div>
                    {product?.discount === 0 ? null :
                      <div className="flex gap-2">
                        <p className="text-sm">
                          You save {formatCurrency(Math.ceil(product?.price) - Math.ceil(product?.discountedPrice))}
                        </p>
                        <Badge variant="outline">{Math.ceil(product?.discount)}% off</Badge>
                      </div>
                    }
                  </div>
                )}
              </div>
              <div>
                <div className="flex gap-2 items-center">
                  {product?.countInStock <= 0 && <><DatabaseZap size={14} color="#EA0303" /> <span className="font-medium text-custom-red">Out of stcok</span></>}
                  {product?.countInStock > 0 && <><DatabaseZap size={14} color="#039F00" /> <span className="font-medium text-custom-text-green">In Stock</span></>}
                  <Badge variant="outline">Code: {product?.barcode}</Badge>
                </div>
              </div>
              <div className="w-full flex items-center gap-2 mt-4">
                <Link href={`/product/${(product?.slug)}`}>
                  <Button className='bg-custom-blue hover:bg-custom-blue h-full text-sm text-white w-full rounded-none flex items-center gap-2'>View Details</Button>
                </Link>
                <AddToCart product={product} />
              </div>
            </div>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

export default ProductView
