"use client";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { CartContext } from "@/context/CartContext";
import { trimText } from "@/lib/trimText";
import { tagEvent } from "@/utils/tagEvent";
import { ShoppingCart } from "lucide-react";
import { useContext } from "react";

const AddToCart = ({ product }) => {
  const { addToCart } = useContext(CartContext);

  return (
    <Button
      className="bg-custom-blue h-full  text-sm text-white w-full rounded-none flex items-center gap-2 "
      onClick={() => {
        if (product?.countInStock === 0) {
          toast({
            title: "Contact us",
            description: "Contact us for product request",
          });
        } else {
          toast({
            title: "Added to cart",
            description: `${trimText(
              product?.title,
              20
            )} was added to the shopping cart.`,
          });
          addToCart(product);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({ ecommerce: null });
    
          window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              currency: "BDT",
              value: product?.discountedPrice,
              items: [
                {
                  item_id: product?._id,
                  item_name: product?.title,
                  item_category: product?.category,
                  item_subcategory: product?.subCategory,
                  item_type: product?.type || product?.brand,
                  item_variant: product?.variant || "",
                  price: product?.discountedPrice,
                  quantity: 1,
                },
              ],
            },
          });
        }
      }}
    >
      <ShoppingCart size={16} /> Add to cart
    </Button>
  );
};

export default AddToCart;
