"use client";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductImage = ({ product }) => {
  return (
    <LazyLoadImage
      src={product?.images?.[0]}
      alt={product?.title}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = "/placeholder.jpg";
      }}
      effect="blur"
      className="w-full object-cover h-full"
    />
  );
};

export default ProductImage;
