import StarRating from "@/custom/StarRating";
import { Badge } from "@/components/ui/badge";
import { trimText } from "@/lib/trimText";
import Link from "next/link";
import { handleViewProduct } from "@/utils/RecentlyViewed";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import ProductImage from "@/components/layout/ProductImage";
import AddWishlist from "./Wishlist";
import ProductView from "./ProductView";
import AddToCart from "./AddToCart";
import { formatCurrency } from "@/utils/formatCurrency";

const ProductCard = ({ product }) => {
  return (
    <Card className="group rounded-none border p-2 bg-white flex h-80 flex-col items-center justify-between relative overflow-hidden">
      <CardHeader className="p-0 h-[64%] relative group">
        <Link
          href={`/product/${product?.slug}`}
          aria-label={`View details for ${product?.name}`}
          onClick={() => handleViewProduct(product)}
          className="h-full overflow-hidden"
        >
          {/* <Image
            src={product?.images?.[0]}
            alt={product?.title}
            className="w-full object-cover h-full"
            width={100}
            height={100}
          /> */}
          <ProductImage product={product} />
        </Link>
        <div className="absolute top-0 opacity-0 -right-2 group-hover:opacity-100 group-hover:right-0 bg-white h-0 group-hover:h-20 w-0 group-hover:w-10 flex flex-col items-center justify-center gap-4 tailwindAnimation">
          <AddWishlist product={product} />
          <ProductView product={product} />
        </div>
      </CardHeader>
      <CardContent className="p-0 w-full">
        <p className="text-sm font-medium text-left capitalize">
          {trimText(product?.title, 20)}
        </p>
        <p className="text-xs">{trimText(product?.author?.name, 20)}</p>
        <div className="flex flex-col items-start w-full">
          {product?.discount === 0 ? (
            <div className="flex items-center justify-between mt-4 w-full">
              <p className="text-sm text-custom-text-blue  font-medium">
                {formatCurrency(product?.price)}
              </p>
              <div className="">
                <StarRating
                  rate={
                    product?.reviews?.length
                      ? product?.rating / product?.reviews?.length
                      : product?.rating
                  }
                />
                <p> ({product?.reviews?.length}) Reviews</p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-start w-full">
              <div className="flex items-center justify-end w-full my-1">
                <StarRating
                  rate={
                    product?.reviews?.length
                      ? product?.rating / product?.reviews?.length
                      : product?.rating
                  }
                />
                {product?.reviews?.length > 0 && (
                  <p>({product?.reviews?.length})</p>
                )}
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-start flex-col">
                  <p className="text-sm text-custom-text-blue font-medium">
                    {formatCurrency(Math.round(product?.discountedPrice))}
                  </p>
                  <p className="text-sm text-gray-500 line-through">
                    {formatCurrency(product?.price)}
                  </p>
                </div>
                {product?.discount === 0 ? null : (
                  <Badge className="bg-[#005c7a] select-none text-white text-xs">
                    {Math.ceil(product?.discount)}% off
                  </Badge>
                )}
              </div>
            </div>
          )}
        </div>
      </CardContent>
      <CardFooter className="h-0 w-0 absolute -bottom-20 group-hover:bottom-0 group-hover:h-12 group-hover:w-full p-0 tailwindAnimation">
        <AddToCart product={product} />
      </CardFooter>
    </Card>
  );
};

export default ProductCard;
