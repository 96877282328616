"use client";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";
import { useMemo } from "react";

const StarRating = ({ rate, size }) => {
  const filledStars = useMemo(() => Math.max(0, Math.floor(rate)), [rate]);
  const hashalfstar = rate % 1 !== 0;
  const starSize = size || 14;

  const filledStarElements = useMemo(() => {
    const elements = [];
    for (let i = 0; i < filledStars; i++) {
      elements.push(<FaStar size={starSize} key={i} color="#FBAE17" />);
    }
    if (hashalfstar) {
      elements.push(
        <FaStarHalfAlt
          size={starSize}
          key={`half-${3}`}
          color="#FBAE17"
          hashalfstar={true}
        />
      );
    }
    return elements;
  }, [filledStars, hashalfstar, starSize]);

  const emptyStarElements = useMemo(() => {
    const elements = [];
    for (let i = 0; i < 5 - filledStars - (hashalfstar ? 1 : 0); i++) {
      elements.push(
        <FaRegStar
          size={starSize}
          key={`empty-${filledStars + i}`}
          color="#9E9E9E"
        />
      );
    }
    return elements;
  }, [filledStars, hashalfstar, starSize]);

  return (
    <div className="flex items-center gap-0.5">
      {filledStarElements}
      {emptyStarElements}
    </div>
  );
};

export default StarRating;
