export function handleViewProduct(product) {
  const productId = product._id;

  // Retrieve recently viewed products from localStorage
  const recentlyViewedProducts = localStorage.getItem('recentlyViewedProducts');
  const parsedProducts = recentlyViewedProducts ? JSON.parse(recentlyViewedProducts) : [];

  // Check if the product already exists in the recently viewed list
  const productExists = parsedProducts.some(p => p._id === productId);

  if (!productExists) {
    // Add the new product to the beginning of the list
    parsedProducts.unshift(product);

    // Ensure the list has a maximum of 10 products
    if (parsedProducts.length > 10) {
      parsedProducts.pop();
    }

    // Update localStorage
    localStorage.setItem('recentlyViewedProducts', JSON.stringify(parsedProducts));
  }

  // Send a request to update the most viewed products
  fetch('/api/most-viewed', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ product }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    })
    .catch((error) => {
      console.error('Error updating viewed products:', error);
    });
}
